import React, { useState } from 'react';
import { icons, IconDisplay } from '../../shared/IconComponents';

const colorOptions = [
  { name: 'Blue', value: '#3B82F6' },
  { name: 'Red', value: '#EF4444' },
  { name: 'Green', value: '#10B981' },
  { name: 'Purple', value: '#8B5CF6' },
  { name: 'Orange', value: '#F97316' },
  { name: 'Pink', value: '#EC4899' },
  { name: 'Teal', value: '#14B8A6' },
  { name: 'Indigo', value: '#6366F1' },
  { name: 'Yellow', value: '#EAB308' },
  { name: 'Cyan', value: '#06B6D4' },
  { name: 'Custom', value: 'custom' }
];

const ValuesEditor = ({ valuesData, setValuesData, onSave }) => {
  const [customColor, setCustomColor] = useState('');
  const [showIconPreview, setShowIconPreview] = useState(false);
  const [selectedPreviewIcon, setSelectedPreviewIcon] = useState(null);

  const handleValueChange = (index, field, value) => {
    console.log('Changing value:', field, value); // Debug log
    const newValues = [...valuesData.values];
    newValues[index] = {
      ...newValues[index],
      [field]: value
    };
    setValuesData({
      ...valuesData,
      values: newValues
    });
  };

  const handleAddValue = () => {
    const newValue = {
      iconName: 'Star',
      color: '#3B82F6',
      title: 'New Value',
      description: 'Enter value description'
    };
    setValuesData({
      values: [...valuesData.values, newValue]
    });
  };

  const handleRemoveValue = (index) => {
    const newValues = valuesData.values.filter((_, i) => i !== index);
    setValuesData({ values: newValues });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Edit Values Section</h3>

      {/* Icon Preview Modal */}
      {showIconPreview && selectedPreviewIcon && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md w-full">
            <div className="flex justify-between items-center mb-4">
              <h4 className="text-lg font-semibold">{selectedPreviewIcon} Icon Preview</h4>
              <button
                onClick={() => setShowIconPreview(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
            <div className="flex justify-center mb-4">
              <IconDisplay iconName={selectedPreviewIcon} color="#000000" size="h-24 w-24" />
            </div>
            <button
              onClick={() => setShowIconPreview(false)}
              className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Close Preview
            </button>
          </div>
        </div>
      )}

      <div className="space-y-6">
        {valuesData.values.map((value, index) => (
          <div key={index} className="p-4 bg-gray-100 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-4">
                <IconDisplay 
                  iconName={value.iconName}
                  color={value.color} 
                />
                <h4 className="font-medium">Value {index + 1}</h4>
              </div>
              <button
                className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={() => handleRemoveValue(index)}
              >
                Remove
              </button>
            </div>

            <div className="space-y-4">
              {/* Icon Selection */}
              <div>
                <label className="block text-sm font-medium mb-1">Icon</label>
                <div className="flex gap-2">
                  <select
                    value={value.iconName}
                    onChange={(e) => {
                      console.log('Selecting new icon:', e.target.value);
                      handleValueChange(index, 'iconName', e.target.value);
                    }}
                    className="flex-1 rounded-md border px-3 py-2"
                  >
                    {Object.keys(icons).map((name) => (
                      <option key={name} value={name}>{name}</option>
                    ))}
                  </select>
                  <button
                    onClick={() => {
                      setSelectedPreviewIcon(value.iconName);
                      setShowIconPreview(true);
                    }}
                    className="px-2 py-1 bg-gray-200 rounded hover:bg-gray-300"
                    title="Preview Icon"
                  >
                    👁️
                  </button>
                </div>
              </div>

              {/* Color Selection */}
              <div>
                <label className="block text-sm font-medium mb-1">Icon Color</label>
                <div className="flex flex-wrap gap-2">
                  {colorOptions.map((color) => (
                    <button
                      key={color.value}
                      onClick={() => {
                        if (color.value === 'custom') {
                          setCustomColor(value.color);
                        } else {
                          handleValueChange(index, 'color', color.value);
                        }
                      }}
                      className={`w-8 h-8 rounded-full border-2 ${
                        value.color === color.value ? 'border-blue-500' : 'border-transparent'
                      }`}
                      style={{ 
                        backgroundColor: color.value === 'custom' ? '#FFFFFF' : color.value,
                        border: color.value === 'custom' ? '2px dashed #666' : undefined
                      }}
                      title={color.name}
                    />
                  ))}
                </div>
                {customColor && (
                  <div className="flex gap-2 items-center mt-2">
                    <input
                      type="color"
                      value={customColor}
                      onChange={(e) => setCustomColor(e.target.value)}
                      className="w-8 h-8"
                    />
                    <button
                      onClick={() => {
                        handleValueChange(index, 'color', customColor);
                        setCustomColor('');
                      }}
                      className="px-2 py-1 bg-blue-500 text-white text-sm rounded"
                    >
                      Apply Custom Color
                    </button>
                  </div>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Title</label>
                <input
                  type="text"
                  value={value.title}
                  onChange={(e) => handleValueChange(index, 'title', e.target.value)}
                  className="w-full rounded-md border px-3 py-2"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Description</label>
                <textarea
                  value={value.description}
                  onChange={(e) => handleValueChange(index, 'description', e.target.value)}
                  rows={2}
                  className="w-full rounded-md border px-3 py-2"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-6 space-y-4">
        <button
          className="w-full px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          onClick={handleAddValue}
        >
          Add New Value
        </button>

        <button
          className="w-full px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          onClick={onSave}
        >
          Save Values Section
        </button>
      </div>
    </div>
  );
};

export default ValuesEditor;