import React from 'react';
import { IconDisplay } from '../shared/IconComponents';

const ValueCard = ({ iconName, iconUrl, color, title, description }) => (
  <div className="bg-zinc-900 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow border border-zinc-800">
    <div className="mb-4">
      <IconDisplay 
        iconName={iconName} 
        iconUrl={iconUrl}
        color={color} 
        size="h-12 w-12" 
      />
    </div>
    <h3 className="text-xl font-semibold text-white mb-2">{title}</h3>
    <p className="text-gray-400 leading-relaxed">{description}</p>
  </div>
);

const ValuesSection = ({ valuesData }) => {
  if (!valuesData?.values || valuesData.values.length === 0) {
    return (
      <div className="bg-black py-16">
        <div className="max-w-7xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-white mb-4 text-center">Our Values</h2>
          <p className="text-gray-400 text-center">No values have been added yet.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-black py-16">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-white mb-12 text-center">Our Values</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {valuesData.values.map((value, index) => (
            <ValueCard
              key={index}
              iconName={value.iconName}
              iconUrl={value.iconUrl}
              color={value.color}
              title={value.title}
              description={value.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ValuesSection;