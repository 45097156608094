import React from 'react';
import { Link } from 'react-router-dom';

const BelieveSection = () => {
  return (
    <div className="relative min-h-[600px] w-full flex flex-col items-center justify-center overflow-hidden">
      {/* Background Image with Overlay */}
      <div 
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: 'url("https://framerusercontent.com/images/0qkpAqGnCtgYMSOPUR7eJBLRVxg.jpg")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: '0.54'
        }}
      />

      {/* Decorative Lines */}
      <div className="absolute inset-0 z-10" style={{ opacity: '0.1' }}>
        {[...Array(6)].map((_, index) => (
          <div
            key={index}
            className="absolute w-px h-full bg-white"
            style={{ left: `${(index + 1) * (100 / 7)}%` }}
          />
        ))}
      </div>

      {/* Content */}
      <div className="relative z-20 text-center px-4 max-w-7xl mx-auto">
        <h2 className="text-white text-7xl mb-8 font-bowlby">
          WHAT WE BELIEVE THAT
        </h2>
        <h3 className="text-white text-5xl mb-12 font-poppins capitalize leading-relaxed max-w-5xl mx-auto">
          Where Innovation Meets Imagination, We Redefine What's Possible
        </h3>
        
        {/* Buttons */}
        <div className="flex gap-6 justify-center">
          <Link
            to="/proposal"
            className="px-8 py-3 bg-white text-black rounded-xl font-poppins font-semibold text-lg hover:bg-gray-100 transition-colors"
          >
            Proposal Folder
          </Link>
          <Link
            to="/partner"
            className="px-8 py-3 bg-blue-600 text-white rounded-xl font-atkinson text-lg hover:bg-blue-700 transition-colors"
          >
            Partner Up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BelieveSection;