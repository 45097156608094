import React, { useRef } from 'react';

const OfferEditor = ({ offerData, setOfferData, onSave }) => {
  const fileInputRefs = useRef([]);

  const handleTitleChange = (newTitle) => {
    setOfferData({
      ...offerData,
      title: newTitle
    });
  };

  const handleOfferChange = (index, field, value) => {
    const newOffers = [...offerData.offers];
    newOffers[index] = {
      ...newOffers[index],
      [field]: value
    };
    setOfferData({
      ...offerData,
      offers: newOffers
    });
  };

  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        handleOfferChange(index, 'image', e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = (index) => {
    fileInputRefs.current[index]?.click();
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Edit Offers Section</h3>
      
      {/* Section Title */}
      <div className="mb-6">
        <label className="block text-sm font-medium mb-1">Section Title</label>
        <input
          type="text"
          value={offerData.title}
          onChange={(e) => handleTitleChange(e.target.value)}
          className="w-full rounded-md border px-3 py-2"
        />
      </div>

      {/* Offers */}
      <div className="space-y-6">
        {offerData.offers.map((offer, index) => (
          <div key={index} className="p-4 bg-gray-100 rounded-lg">
            <h4 className="font-medium mb-4">Offer {index + 1}</h4>
            
            {/* Image Upload */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Image</label>
              <div className="flex flex-col items-center gap-2">
                {offer.image && (
                  <img 
                    src={offer.image} 
                    alt={offer.title} 
                    className="w-full max-w-md h-48 object-cover rounded-lg mb-2"
                  />
                )}
                <input
                  type="file"
                  ref={el => fileInputRefs.current[index] = el}
                  onChange={(e) => handleImageUpload(index, e)}
                  accept="image/*"
                  className="hidden"
                />
                <button
                  onClick={() => triggerFileInput(index)}
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                  {offer.image ? 'Change Image' : 'Upload Image'}
                </button>
              </div>
            </div>

            {/* Title */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Title</label>
              <input
                type="text"
                value={offer.title}
                onChange={(e) => handleOfferChange(index, 'title', e.target.value)}
                className="w-full rounded-md border px-3 py-2"
              />
            </div>

            {/* Short Description */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Short Description</label>
              <textarea
                value={offer.description}
                onChange={(e) => handleOfferChange(index, 'description', e.target.value)}
                rows={2}
                className="w-full rounded-md border px-3 py-2"
              />
            </div>

            {/* Long Description */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Long Description</label>
              <textarea
                value={offer.longDescription}
                onChange={(e) => handleOfferChange(index, 'longDescription', e.target.value)}
                rows={4}
                className="w-full rounded-md border px-3 py-2"
              />
            </div>

            {/* Link */}
            <div>
              <label className="block text-sm font-medium mb-1">Link</label>
              <input
                type="text"
                value={offer.link}
                onChange={(e) => handleOfferChange(index, 'link', e.target.value)}
                className="w-full rounded-md border px-3 py-2"
              />
            </div>
          </div>
        ))}
      </div>

      <button
        className="mt-6 w-full px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        onClick={onSave}
      >
        Save Offers Section
      </button>
    </div>
  );
};

export default OfferEditor;