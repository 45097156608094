import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import MissionSection from './MissionSection';
import ValuesSection from './ValuesSection';
import OfferSection from './OfferSection';
import TrustedBySection from './TrustedBySection';
import defaultKeyImage from '../assets/key.avif';
import defaultHeroImage from '../assets/hero.avif';
import museumImage from '../assets/museum.avif';
import metaverseImage from '../assets/metaverse.avif';
import arImage from '../assets/ar.avif';
import specificityLogo from '../assets/partners/specificity.webp';
import gizLogo from '../assets/partners/giz.webp';
import ticdceLogo from '../assets/partners/ticdce.webp';
import ostLogo from '../assets/partners/ost.webp';
import startupLogo from '../assets/partners/startup.png';
import darajafourLogo from '../assets/partners/idwey.webp';
import BelieveSection from './BelieveSection';
import SDGImpactSection from './SDGImpactSection';
import TeamSection from './TeamSection';
import ContactSection from './ContactSection';
import Footer from './Footer';

const HomePage = () => {
  const [navItems, setNavItems] = useState([
    { text: 'Tynass Trips', url: '/trips' },
    { text: 'About Us', url: '/about' },
    { text: 'Contact Us', url: '/contact' }
  ]);

  const [heroData, setHeroData] = useState({
    title: "Tynass IT",
    mainText: {
      line1: { part1: "YOUR KEY", part2: "TO THE" },
      line2: { part1: "FUTURE", part2: "AND TO" },
      line3: { part1: "ENHANCE", part2: "THE PAST" }
    },
    buttonText: "Partner Up",
    buttonLink: "/partner",
    keyImage: defaultKeyImage
  });

  const [aboutData, setAboutData] = useState({
    title: "Redefine Innovation with Tynass",
    description: "At Tynass, we've taken technology and innovation to a whole new level...",
    heroImage: defaultHeroImage
  });

  const [missionData, setMissionData] = useState({
    mission: "Our mission is to redefine the way people interact with their surroundings by leveraging cutting-edge technology to offer immersive and enriching experiences. We aim to make history, culture, and education accessible, engaging, and entertaining for everyone.",
    vision: "Our vision is to become a global leader in augmented and mixed reality solutions. We envision a world where Tynass is synonymous with innovation and transformation, shaping the way individuals and organizations explore and connect with their environments.",
    goal: "Our ultimate goal is to continue innovating and expanding our offerings, making augmented reality more accessible and user-friendly. We want to empower our users and partners to unlock the full potential of augmented reality, revolutionizing how they engage with history, culture, and more.",
    collaboration: "Collaborate with Tynass Trips and become a key player in the travel industry by offering innovative and immersive experiences to customers. Together, let's revolutionize the way people travel."
  });

  const [valuesData, setValuesData] = useState({
    values: [
      {
        iconName: 'Zap',
        color: '#3B82F6',
        title: "Innovation",
        description: "We constantly seek out new and better ways to solve problems."
      },
      {
        iconName: 'Users',
        color: '#10B981',
        title: "Collaboration",
        description: "We work as a team to achieve our goals and celebrate our successes."
      },
      {
        iconName: 'Heart',
        color: '#EF4444',
        title: "Empathy",
        description: "We put ourselves in the shoes of our customers to understand their needs."
      },
      {
        iconName: 'Globe',
        color: '#8B5CF6',
        title: "Diversity",
        description: "We believe in building a diverse and inclusive team."
      },
      {
        iconName: 'Target',
        color: '#F97316',
        title: "Excellence",
        description: "We are passionate about delivering exceptional results."
      },
      {
        iconName: 'Shield',
        color: '#14B8A6',
        title: "Trust",
        description: "We build trust through transparency and integrity."
      }
    ]
  });

  const [offerData, setOfferData] = useState({
    title: "What we Offer",
    offers: [
      {
        image: museumImage,
        title: "Auto guided Tours & Experiences",
        description: "We offer an extraordinary adventure through our flagship product, Tynass Trips.",
        longDescription: "We offer an extraordinary adventure through our flagship product, Tynass Trips. Users can explore destinations and engage in captivating experiences using augmented reality and immersive audio narration, all available in multiple languages.\n\nPartner with us to provide your clients with augmented reality and audio-narrated adventures. We specialize in crafting custom experiences or offering our ready-made Tynass Trips, tailored for travel agencies, tourism organizations, museums, art galleries, and more. Elevate your offerings today!",
        link: "/tours"
      },
      {
        image: metaverseImage,
        title: "Metaverse & Immersive Experiences",
        description: "We specialize in developing cutting-edge, customized, and innovative experiences for businesses.",
        longDescription: "We specialize in developing cutting-edge, customized, and innovative experiences for businesses. Utilizing advanced technologies like augmented reality and virtual reality.\n\nWe tailor solutions to meet your specific demands. This not only sets your business apart from the competition but also enables you to offer unique experiences and products to your clients, particularly appealing to the new generation of customers. Stand out and attract new clients with our transformative solutions.",
        link: "/metaverse"
      },
      {
        image: arImage,
        title: "Elevate Your Brand With Cutting-Edge AR Social Media Campaigns!",
        description: "Experience the future of marketing through augmented reality on major platforms.",
        longDescription: "Experience the future of marketing through augmented reality on major platforms like Facebook, TikTok, Instagram, and Snapchat. We specialize in crafting engaging AR experiences that redefine your brand's online presence.\n\nOur innovative approach focuses on the advantages of AR marketing, ensuring your brand stands out in the crowded digital landscape. Join the AR revolution today and captivate your audience with immersive AR experiences that set you apart from the competition.",
        link: "/ar-campaigns"
      }
    ]
  });

  const [trustedByData, setTrustedByData] = useState({
    title: "WE ARE TRUSTED BY",
    partners: [
      {
        id: 1,
        name: 'Specificity',
        logo: specificityLogo,
        url: 'https://specificity.com'
      },
      {
        id: 2,
        name: 'GIZ',
        logo: gizLogo,
        url: 'https://giz.com'
      },
      {
        id: 3,
        name: 'TICDCE',
        logo: ticdceLogo,
        url: 'https://ticdce.com'
      },
      {
        id: 4,
        name: 'OST',
        logo: ostLogo,
        url: 'https://ost.com'
      },
      {
        id: 5,
        name: 'Startup',
        logo: startupLogo,
        url: 'https://startup.com'
      },
      {
        id: 6,
        name: 'Daraja Four',
        logo: darajafourLogo,
        url: 'https://darajafour.com'
      }
    ]
  });

  useEffect(() => {
    const loadSavedData = () => {
      try {
        const savedNavItems = localStorage.getItem('navItems');
        const savedHeroData = localStorage.getItem('heroData');
        const savedAboutData = localStorage.getItem('aboutData');
        const savedMissionData = localStorage.getItem('missionData');
        const savedValuesData = localStorage.getItem('valuesData');
        const savedOfferData = localStorage.getItem('offerData');
        const savedTrustedByData = localStorage.getItem('trustedByData');

        if (savedNavItems) setNavItems(JSON.parse(savedNavItems));
        if (savedHeroData) setHeroData(JSON.parse(savedHeroData));
        if (savedAboutData) {
          const parsedAboutData = JSON.parse(savedAboutData);
          if (parsedAboutData.heroImage) {
            setAboutData(parsedAboutData);
          }
        }
        if (savedMissionData) setMissionData(JSON.parse(savedMissionData));
        if (savedValuesData) setValuesData(JSON.parse(savedValuesData));
        if (savedOfferData) setOfferData(JSON.parse(savedOfferData));
        if (savedTrustedByData) {
          setTrustedByData(JSON.parse(savedTrustedByData));
        }
      } catch (error) {
        console.error('Error loading saved data:', error);
      }
    };

    loadSavedData();
  }, []);

  return (
    <div className="min-h-screen bg-black">
      {/* Navigation */}
      <Navbar navItems={navItems} />
      
      {/* Main Content */}
      <main>
        <section>
          <HeroSection heroData={heroData} />
        </section>

        <section>
          <AboutSection aboutData={aboutData} />
        </section>

        <section>
          <MissionSection missionData={missionData} />
        </section>

        <section>
          <ValuesSection valuesData={valuesData} />
        </section>

        <section>
          <OfferSection offerData={offerData} />
        </section>

        <section>
          <TrustedBySection trustedByData={trustedByData} />
        </section>
        <section>
        <BelieveSection />
        </section>
        <section>
          <SDGImpactSection />
        </section>
        <section>
        <TeamSection />
        </section>
        <section>
        <ContactSection />
        </section>
        <section>
        <Footer />
        </section>
      </main>
    </div>
  );
};

export default HomePage;