import React, { useRef, useState } from 'react';
import imageCompression from 'browser-image-compression';

const MAX_TITLE_LENGTH = 50;
const MAX_DESCRIPTION_LENGTH = 500;

const AboutEditor = ({ aboutData, setAboutData, onSave }) => {
  const fileInputRef = useRef(null);
  const [errors, setErrors] = useState({
    title: '',
    description: ''
  });

  const compressionOptions = {
    maxSizeMB: 1,
    maxWidthOrHeight: 600,
    useWebWorker: true
  };

  const validateInput = (field, value) => {
    let error = '';
    switch (field) {
      case 'title':
        if (!value.trim()) {
          error = 'Title is required';
        } else if (value.length > MAX_TITLE_LENGTH) {
          error = `Title must be less than ${MAX_TITLE_LENGTH} characters`;
        }
        break;
      case 'description':
        if (!value.trim()) {
          error = 'Description is required';
        } else if (value.length > MAX_DESCRIPTION_LENGTH) {
          error = `Description must be less than ${MAX_DESCRIPTION_LENGTH} characters`;
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleTextChange = (field, value) => {
    const error = validateInput(field, value);
    setErrors(prev => ({ ...prev, [field]: error }));

    if (field === 'title' && value.length <= MAX_TITLE_LENGTH) {
      setAboutData({ ...aboutData, title: value });
    } else if (field === 'description' && value.length <= MAX_DESCRIPTION_LENGTH) {
      setAboutData({ ...aboutData, description: value });
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        alert('Please upload an image file');
        return;
      }

      try {
        const compressedFile = await imageCompression(file, compressionOptions);
        const reader = new FileReader();
        reader.onloadend = () => {
          setAboutData({
            ...aboutData,
            heroImage: reader.result
          });
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error processing image:', error);
        alert('Error processing image. Please try again.');
      }
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    const titleError = validateInput('title', aboutData.title);
    const descriptionError = validateInput('description', aboutData.description);

    if (titleError || descriptionError) {
      setErrors({
        title: titleError,
        description: descriptionError
      });
      return;
    }

    onSave();
  };

  return (
    // Wrapper with max width
    <div className="max-w-3xl mx-auto">
      <div className="space-y-6">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <form onSubmit={handleSave}>
            <h3 className="text-lg font-semibold mb-4">Edit About Section</h3>
            
            {/* Title */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Section Title
                <span className="text-red-500 ml-1">*</span>
                <span className="text-sm text-gray-500 ml-2">
                  ({aboutData.title.length}/{MAX_TITLE_LENGTH})
                </span>
              </label>
              <input
                type="text"
                value={aboutData.title}
                onChange={(e) => handleTextChange('title', e.target.value)}
                className={`mt-1 block w-full rounded-md border px-3 py-2 focus:ring-1 ${
                  errors.title 
                    ? 'border-red-500 focus:ring-red-500' 
                    : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                }`}
                placeholder="Enter section title"
              />
              {errors.title && (
                <p className="mt-1 text-sm text-red-500">{errors.title}</p>
              )}
            </div>

            {/* Description */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
                <span className="text-red-500 ml-1">*</span>
                <span className="text-sm text-gray-500 ml-2">
                  ({aboutData.description.length}/{MAX_DESCRIPTION_LENGTH})
                </span>
              </label>
              <textarea
                value={aboutData.description}
                onChange={(e) => handleTextChange('description', e.target.value)}
                rows={8}
                className={`mt-1 block w-full rounded-md border px-3 py-2 resize-y min-h-[200px] ${
                  errors.description 
                    ? 'border-red-500 focus:ring-red-500' 
                    : 'border-gray-300 focus:border-blue-500 focus:ring-blue-500'
                }`}
                placeholder="Enter section description"
                style={{
                  lineHeight: '1.5',
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word'
                }}
              />
              {errors.description && (
                <p className="mt-1 text-sm text-red-500">{errors.description}</p>
              )}
              <p className="mt-1 text-sm text-gray-500">
                Characters remaining: {MAX_DESCRIPTION_LENGTH - aboutData.description.length}
              </p>
            </div>

            {/* Image Section */}
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Hero Image
                <span className="text-red-500 ml-1">*</span>
              </label>
              
              <div className="border-2 border-dashed border-gray-300 rounded-lg p-6 mb-4 hover:border-blue-500 transition-colors">
                {/* Current Image Preview */}
                <div className="mb-4">
                  <img 
                    src={aboutData.heroImage}
                    alt="Hero"
                    className="max-w-full h-auto max-h-[400px] mx-auto object-contain rounded-lg shadow-sm"
                  />
                </div>

                {/* Upload Controls */}
                <div className="flex flex-col items-center gap-2">
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    accept="image/*"
                    className="hidden"
                  />
                  <button
                    type="button"
                    onClick={() => fileInputRef.current?.click()}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    Choose New Image
                  </button>
                  <p className="text-sm text-gray-500 text-center">
                    Recommended size: 600x600px<br />
                    Maximum file size: 1MB
                  </p>
                </div>
              </div>
            </div>

            {/* Save Button */}
            <button
              type="submit"
              className="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={!!errors.title || !!errors.description}
            >
              Save About Section
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AboutEditor;