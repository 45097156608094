import React from 'react';

export const icons = {
  Zap: <path d="M13 2L3 14h9l-1 8L21 10h-9z"/>,
  Heart: <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"/>,
  Star: <path d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"/>,
  Target: <circle cx="12" cy="12" r="10"/>,
  Trophy: <path d="M8 2h8m-4 0v20M4 10V4h16v6a8 8 0 0 1-16 0z"/>,
  Bulb: <path d="M9 18h6m-3-3v3m3-12h-6m0 0l-3 9h12l-3-9"/>,
  Rocket: <path d="M12 2l9 9-9 9-9-9 9-9zM12 8v8"/>,
  Shield: <path d="M12 22s8-4 8-10V4H4v8c0 6 8 10 8 10z"/>,
  Flag: <path d="M4 22V4c6-4 10 4 16 0v12c-6 4-10-4-16 0"/>,
  Globe: <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm8 8h-3.17c-.11-1.81-.39-3.54-.92-5.07A8 8 0 0 1 20 10z"/>,
  Users: <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"/>,
  Lock: <path d="M19 11H5a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2zm-2-4a5 5 0 0 0-10 0v4h10V7z"/>
};

export const IconDisplay = ({ iconName, iconUrl, color, size = "h-8 w-8" }) => {
  try {
    if (iconUrl) {
      return (
        <img 
          src={iconUrl} 
          alt={iconName || 'Custom icon'} 
          className={size}
          style={{ filter: `drop-shadow(0 0 2px ${color})` }}
          onError={(e) => {
            console.error('Error loading image:', e);
            e.target.style.display = 'none';
          }}
        />
      );
    }

    return (
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        className={size}
        viewBox="0 0 24 24" 
        fill="none" 
        stroke={color} 
        strokeWidth="2"
        strokeLinecap="round" 
        strokeLinejoin="round"
      >
        {icons[iconName] || icons.Star}
      </svg>
    );
  } catch (error) {
    console.error('Error rendering icon:', error);
    return null;
  }
};