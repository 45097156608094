import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../website/Navbar';
import HeroSection from '../website/HeroSection';
import AboutSection from '../website/AboutSection';
import MissionSection from '../website/MissionSection';
import ValuesSection from '../website/ValuesSection';
import OfferSection from '../website/OfferSection';
import TrustedBySection from '../website/TrustedBySection';
import NavbarEditor from './components/NavbarEditor';
import HeroEditor from './components/HeroEditor';
import AboutEditor from './components/AboutEditor';
import MissionEditor from './components/MissionEditor';
import ValuesEditor from './components/ValuesEditor';
import OfferEditor from './components/OfferEditor';
import TrustedByEditor from './components/TrustedByEditor';

import defaultKeyImage from '../assets/key.avif';
import defaultHeroImage from '../assets/hero.avif';
import museumImage from '../assets/museum.avif';
import metaverseImage from '../assets/metaverse.avif';
import arImage from '../assets/ar.avif';
import specificityLogo from '../assets/partners/specificity.webp';
import gizLogo from '../assets/partners/giz.webp';
import ticdceLogo from '../assets/partners/ticdce.webp';
import ostLogo from '../assets/partners/ost.webp';
import startupLogo from '../assets/partners/startup.png';
import darajafourLogo from '../assets/partners/idwey.webp';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState('navbar');
  
  const [navItems, setNavItems] = useState([
    { text: 'Tynass Trips', url: '/trips' },
    { text: 'About Us', url: '/about' },
    { text: 'Contact Us', url: '/contact' }
  ]);
  
  const [heroData, setHeroData] = useState({
    title: "Tynass IT",
    mainText: {
      line1: { part1: "YOUR KEY", part2: "TO THE" },
      line2: { part1: "FUTURE", part2: "AND TO" },
      line3: { part1: "ENHANCE", part2: "THE PAST" }
    },
    buttonText: "Partner Up",
    buttonLink: "/partner",
    keyImage: defaultKeyImage
  });

  const [aboutData, setAboutData] = useState({
    title: "Redefine Innovation with Tynass",
    description: "At Tynass, we've taken technology and innovation to a whole new level...",
    heroImage: defaultHeroImage
  });

  const [missionData, setMissionData] = useState({
    mission: "Our mission is to redefine the way people interact with their surroundings by leveraging cutting-edge technology to offer immersive and enriching experiences. We aim to make history, culture, and education accessible, engaging, and entertaining for everyone.",
    vision: "Our vision is to become a global leader in augmented and mixed reality solutions. We envision a world where Tynass is synonymous with innovation and transformation, shaping the way individuals and organizations explore and connect with their environments.",
    goal: "Our ultimate goal is to continue innovating and expanding our offerings, making augmented reality more accessible and user-friendly. We want to empower our users and partners to unlock the full potential of augmented reality, revolutionizing how they engage with history, culture, and more.",
    collaboration: "Collaborate with Tynass Trips and become a key player in the travel industry by offering innovative and immersive experiences to customers. Together, let's revolutionize the way people travel."
  });

  const [valuesData, setValuesData] = useState({
    values: [
      {
        iconName: "Zap",
        color: "#3B82F6",
        title: "Innovation",
        description: "We constantly seek out new and better ways to solve problems."
      },
      // ... other values remain the same
    ]
  });

  const [offerData, setOfferData] = useState({
    title: "What we Offer",
    offers: [
      {
        image: museumImage,
        title: "Auto guided Tours & Experiences",
        description: "We offer an extraordinary adventure through our flagship product, Tynass Trips.",
        longDescription: "We offer an extraordinary adventure through our flagship product, Tynass Trips. Users can explore destinations and engage in captivating experiences using augmented reality and immersive audio narration, all available in multiple languages.\n\nPartner with us to provide your clients with augmented reality and audio-narrated adventures. We specialize in crafting custom experiences or offering our ready-made Tynass Trips, tailored for travel agencies, tourism organizations, museums, art galleries, and more. Elevate your offerings today!",
        link: "/tours"
      },
      // ... other offers remain the same
    ]
  });

  const [trustedByData, setTrustedByData] = useState({
    title: "WE ARE TRUSTED BY",
    partners: [
      {
        id: 1,
        name: 'Specificity',
        logo: specificityLogo,
        url: 'https://specificity.com'
      },
      {
        id: 2,
        name: 'GIZ',
        logo: gizLogo,
        url: 'https://giz.com'
      },
      {
        id: 3,
        name: 'TICDCE',
        logo: ticdceLogo,
        url: 'https://ticdce.com'
      },
      {
        id: 4,
        name: 'OST',
        logo: ostLogo,
        url: 'https://ost.com'
      },
      {
        id: 5,
        name: 'Startup',
        logo: startupLogo,
        url: 'https://startup.com'
      },
      {
        id: 6,
        name: 'Daraja Four',
        logo: darajafourLogo,
        url: 'https://darajafour.com'
      }
    ]
  });

  const [saveStatus, setSaveStatus] = useState('');

  useEffect(() => {
    loadSavedData();
  }, []);

  const loadSavedData = () => {
    try {
      const savedNavItems = localStorage.getItem('navItems');
      const savedHeroData = localStorage.getItem('heroData');
      const savedAboutData = localStorage.getItem('aboutData');
      const savedMissionData = localStorage.getItem('missionData');
      const savedValuesData = localStorage.getItem('valuesData');
      const savedOfferData = localStorage.getItem('offerData');
      const savedTrustedByData = localStorage.getItem('trustedByData');

      if (savedNavItems) setNavItems(JSON.parse(savedNavItems));
      if (savedHeroData) setHeroData(JSON.parse(savedHeroData));
      if (savedAboutData) {
        const parsedAboutData = JSON.parse(savedAboutData);
        if (parsedAboutData.heroImage) {
          setAboutData(parsedAboutData);
        } else {
          setAboutData({ ...parsedAboutData, heroImage: defaultHeroImage });
        }
      }
      if (savedMissionData) setMissionData(JSON.parse(savedMissionData));
      if (savedValuesData) setValuesData(JSON.parse(savedValuesData));
      if (savedOfferData) setOfferData(JSON.parse(savedOfferData));
      if (savedTrustedByData) setTrustedByData(JSON.parse(savedTrustedByData));
    } catch (error) {
      console.error('Error loading saved data:', error);
    }
  };

  const handleSave = () => {
    try {
      localStorage.setItem('navItems', JSON.stringify(navItems));
      localStorage.setItem('heroData', JSON.stringify(heroData));
      localStorage.setItem('aboutData', JSON.stringify(aboutData));
      localStorage.setItem('missionData', JSON.stringify(missionData));
      localStorage.setItem('valuesData', JSON.stringify(valuesData));
      localStorage.setItem('offerData', JSON.stringify(offerData));
      localStorage.setItem('trustedByData', JSON.stringify(trustedByData));
      showSaveStatus('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving data:', error);
      showSaveStatus('Error saving changes. Please try again.');
    }
  };

  const showSaveStatus = (message) => {
    setSaveStatus(message);
    setTimeout(() => setSaveStatus(''), 3000);
  };

  const handleLogout = () => {
    localStorage.removeItem('isAdmin');
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Live Preview */}
      <div className="border-b">
        <Navbar navItems={navItems} />
        <HeroSection heroData={heroData} />
        <AboutSection aboutData={aboutData} />
        <MissionSection missionData={missionData} />
        <ValuesSection valuesData={valuesData} />
        <OfferSection offerData={offerData} />
        <TrustedBySection trustedByData={trustedByData} />
      </div>

      {/* Admin Interface */}
      <div className="max-w-4xl mx-auto p-6">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">Website Builder</h1>
          <div className="flex gap-4">
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
            >
              Save All Changes
            </button>
            <button
              onClick={handleLogout}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
            >
              Logout
            </button>
          </div>
        </div>

        {/* Save Status Message */}
        {saveStatus && (
          <div className="mb-4 p-3 bg-green-100 text-green-700 rounded">
            {saveStatus}
          </div>
        )}

        {/* Section Tabs */}
        <div className="mb-6 border-b flex flex-wrap">
          <button
            className={`px-4 py-2 mr-4 ${activeSection === 'navbar' ? 'border-b-2 border-blue-600' : ''}`}
            onClick={() => setActiveSection('navbar')}
          >
            Navigation
          </button>
          <button
            className={`px-4 py-2 mr-4 ${activeSection === 'hero' ? 'border-b-2 border-blue-600' : ''}`}
            onClick={() => setActiveSection('hero')}
          >
            Hero Section
          </button>
          <button
            className={`px-4 py-2 mr-4 ${activeSection === 'about' ? 'border-b-2 border-blue-600' : ''}`}
            onClick={() => setActiveSection('about')}
          >
            About Section
          </button>
          <button
            className={`px-4 py-2 mr-4 ${activeSection === 'mission' ? 'border-b-2 border-blue-600' : ''}`}
            onClick={() => setActiveSection('mission')}
          >
            Mission & Vision
          </button>
          <button
            className={`px-4 py-2 mr-4 ${activeSection === 'values' ? 'border-b-2 border-blue-600' : ''}`}
            onClick={() => setActiveSection('values')}
          >
            Values
          </button>
          <button
            className={`px-4 py-2 mr-4 ${activeSection === 'offers' ? 'border-b-2 border-blue-600' : ''}`}
            onClick={() => setActiveSection('offers')}
          >
            Offers
          </button>
          <button
            className={`px-4 py-2 ${activeSection === 'trusted' ? 'border-b-2 border-blue-600' : ''}`}
            onClick={() => setActiveSection('trusted')}
          >
            Trusted By
          </button>
        </div>

        {/* Content Editors */}
        {activeSection === 'navbar' && (
          <NavbarEditor 
            navItems={navItems} 
            setNavItems={setNavItems}
            onSave={() => showSaveStatus('Navigation updated successfully!')}
          />
        )}
        {activeSection === 'hero' && (
          <HeroEditor 
            heroData={heroData}
            setHeroData={setHeroData}
            onSave={() => showSaveStatus('Hero section updated successfully!')}
          />
        )}
        {activeSection === 'about' && (
          <AboutEditor 
            aboutData={aboutData}
            setAboutData={setAboutData}
            onSave={() => showSaveStatus('About section updated successfully!')}
          />
        )}
        {activeSection === 'mission' && (
          <MissionEditor 
            missionData={missionData}
            setMissionData={setMissionData}
            onSave={() => showSaveStatus('Mission section updated successfully!')}
          />
        )}
        {activeSection === 'values' && (
          <ValuesEditor 
            valuesData={valuesData}
            setValuesData={setValuesData}
            onSave={() => showSaveStatus('Values section updated successfully!')}
          />
        )}
        {activeSection === 'offers' && (
          <OfferEditor 
            offerData={offerData}
            setOfferData={setOfferData}
            onSave={() => showSaveStatus('Offers section updated successfully!')}
          />
        )}
        {activeSection === 'trusted' && (
          <TrustedByEditor 
            trustedByData={trustedByData}
            setTrustedByData={setTrustedByData}
            onSave={() => showSaveStatus('Trusted By section updated successfully!')}
          />
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;