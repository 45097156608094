import React from 'react';

const ContactSection = () => {
  return (
    <div className="relative bg-black min-h-screen flex flex-col items-center justify-center p-4">
      <div className="text-center text-white mb-4">
        <h2 className="text-5xl font-bold">Let's get to know each other.</h2>
        <h2 className="text-5xl font-bold text-blue-600">Get in touch.</h2>
      </div>
      <div className="relative w-full max-w-7xl mt-4">
        <div className="overflow-hidden rounded-lg">
          <div className="absolute inset-0 bg-black opacity-50" />
          <video
            src="https://framerusercontent.com/assets/9cayyjbmosS5EVxm1MARsvFvclo.mp4"
            loop
            autoPlay
            muted
            playsInline
            className="w-[1920px] h-[500px] object-cover"
            style={{ borderRadius: '19px' }}
          />
        </div>
        <div className="absolute inset-0 flex flex-col justify-center p-4 w-1/2">
          
          <h1 className="text-xl text-white">Tynass IT x You</h1>
          <h1 className="text-5xl font-bold text-white uppercase mb-2">
            WE BELIEVE IN CO-EXISTING SO HIT US UP PARTNER!
          </h1>
          <div className="flex gap-4 mt-4">
            <a href="./" className="bg-white text-black px-4 py-2 rounded">
              Explore our App
            </a>
            <a href="./" className="bg-blue-600 text-white px-4 py-2 rounded">
              Partner Up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;