import React from 'react';
import { Link } from 'react-router-dom';
import keyImage from '../website/key.avif'
import './HeroSection.css';

const HeroSection = ({ heroData }) => {
  const {
    title = "Tynass IT",
    mainText = {
      line1: { part1: "YOUR KEY", part2: "TO THE" },
      line2: { part1: "FUTURE", part2: "AND TO" },
      line3: { part1: "ENHANCE", part2: "THE PAST" }
    },
    buttonText = "Partner Up",
    buttonLink = "/partner"
  } = heroData || {};

  return (
    <div className="bg-black min-h-[600px] flex items-center font-poppins">
      <div className="max-w-7xl mx-auto px-4 py-16 grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        {/* Key Image Side */}
        <div className="flex justify-center md:justify-start">
          <picture>
            <source srcSet={keyImage} type="image/avif" />
            <img 
              src={keyImage} 
              alt="Tynass Key Logo"
              className="w-auto h-auto max-w-full animate-key"
              style={{ maxWidth: '400px' }} // Adjust size as needed
            />
          </picture>
        </div>

        {/* Text Side */}
        <div className="text-white">
          <h1 
            className="text-[#E7255D] text-[75px] font-bold mb-8 leading-tight animate-title"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            {title}
          </h1>
          <div className="space-y-1">
            <p className="text-[50px] leading-tight animate-text" style={{ animationDelay: '0.5s' }}>
              <span className="text-blue-600">{mainText.line1.part1}</span>
              <span className="text-white"> {mainText.line1.part2}</span>
            </p>
            <p className="text-[50px] leading-tight animate-text" style={{ animationDelay: '0.7s' }}>
              <span className="text-blue-600">{mainText.line2.part1}</span>
              <span className="text-white"> {mainText.line2.part2}</span>
            </p>
            <p className="text-[50px] leading-tight animate-text" style={{ animationDelay: '0.9s' }}>
              <span className="text-white">{mainText.line3.part1} </span>
              <span className="text-blue-600">{mainText.line3.part2}</span>
            </p>
          </div>
          <div className="pt-8">
            <Link
              to={buttonLink}
              className="bg-blue-600 text-white px-8 py-3 rounded-full hover:bg-blue-700 transition-colors inline-block animate-button"
            >
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;