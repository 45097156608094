import React from 'react';

const MissionSection = ({ missionData = {} }) => {
  const {
    mission = "Our mission is to redefine the way people interact with their surroundings by leveraging cutting-edge technology to offer immersive and enriching experiences. We aim to make history, culture, and education accessible, engaging, and entertaining for everyone.",
    vision = "Our vision is to become a global leader in augmented and mixed reality solutions. We envision a world where Tynass is synonymous with innovation and transformation, shaping the way individuals and organizations explore and connect with their environments.",
    goal = "Our ultimate goal is to continue innovating and expanding our offerings, making augmented reality more accessible and user-friendly. We want to empower our users and partners to unlock the full potential of augmented reality, revolutionizing how they engage with history, culture, and more.",
    collaboration = "Collaborate with Tynass Trips and become a key player in the travel industry by offering innovative and immersive experiences to customers. Together, let's revolutionize the way people travel."
  } = missionData;

  return (
    <div className="bg-black">
      <div className="max-w-7xl mx-auto px-4">
        <div className="space-y-6">
          {/* Mission */}
          <div>
            <h2 className="text-white text-2xl font-bold mb-2">Mission:</h2>
            <p className="text-gray-300 text-base leading-relaxed">
              {mission}
            </p>
          </div>

          {/* Vision */}
          <div>
            <h2 className="text-white text-2xl font-bold mb-2">Vision:</h2>
            <p className="text-gray-300 text-base leading-relaxed">
              {vision}
            </p>
          </div>

          {/* Goal */}
          <div>
            <h2 className="text-white text-2xl font-bold mb-2">Goal:</h2>
            <p className="text-gray-300 text-base leading-relaxed">
              {goal}
            </p>
          </div>

          {/* Collaboration */}
          <div className="pt-2">
            <p className="text-gray-300 text-base leading-relaxed italic">
              {collaboration}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionSection;