import React, { useState } from 'react';

const NavbarEditor = ({ navItems, setNavItems, onSave }) => {
  const [newLink, setNewLink] = useState({ text: '', url: '' });

  const handleAddLink = (e) => {
    e.preventDefault();
    if (newLink.text && newLink.url) {
      setNavItems([...navItems, newLink]);
      setNewLink({ text: '', url: '' });
      onSave();
    }
  };

  const handleDeleteLink = (index) => {
    setNavItems(navItems.filter((_, i) => i !== index));
    onSave();
  };

  return (
    <div className="space-y-6">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h2 className="text-lg font-semibold mb-4">Add Navigation Link</h2>
        <form onSubmit={handleAddLink} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Link Text
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={newLink.text}
                onChange={(e) => setNewLink({ ...newLink, text: e.target.value })}
                placeholder="e.g., About Us"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                URL
              </label>
              <input
                type="text"
                className="w-full p-2 border rounded"
                value={newLink.url}
                onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
                placeholder="e.g., /about"
              />
            </div>
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
          >
            Add Link
          </button>
        </form>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h2 className="text-lg font-semibold mb-4">Current Navigation Links</h2>
        <div className="space-y-3">
          {navItems.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-3 border rounded hover:bg-gray-50"
            >
              <div>
                <p className="font-medium">{item.text}</p>
                <p className="text-sm text-gray-500">{item.url}</p>
              </div>
              <button
                onClick={() => handleDeleteLink(index)}
                className="px-3 py-1 text-red-600 hover:bg-red-50 rounded"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NavbarEditor;