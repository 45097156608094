import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ navItems }) => {
  return (
    <nav className="bg-blue-600 px-4 py-4">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="text-white text-2xl font-bold">
            ◆ Tynass
          </Link>
        </div>
        <div className="flex space-x-8">
          {navItems.map((item, index) => (
            <Link
              key={index}
              to={item.url}
              className="text-blue-200 hover:text-white transition-colors"
            >
              {item.text}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;