import React from 'react';
import { Link } from 'react-router-dom';

const OfferCard = ({ image, title, description, longDescription, link }) => (
  <div className="flex flex-col">
    <div className="relative h-64 mb-4 overflow-hidden rounded-lg">
      <img 
        src={image} 
        alt={title}
        className="object-cover w-full h-full hover:scale-110 transition-transform duration-500"
      />
    </div>
    <h3 className="text-2xl font-bold text-white mb-4">{title}</h3>
    <p className="text-gray-400 mb-4">{description}</p>
    <p className="text-gray-400 mb-6">{longDescription}</p>
    <Link 
      to={link} 
      className="text-blue-500 hover:text-blue-400 transition-colors mt-auto inline-flex items-center"
    >
      Discover more
      <svg 
        className="w-4 h-4 ml-2" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={2} 
          d="M9 5l7 7-7 7"
        />
      </svg>
    </Link>
  </div>
);

const OfferSection = ({ offerData }) => {
  return (
    <div className="bg-black py-16">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-white text-center mb-12">
          {offerData.title || "What we Offer"}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {offerData.offers.map((offer, index) => (
            <OfferCard
              key={index}
              {...offer}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OfferSection;