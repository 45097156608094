import React from 'react';

const HeroEditor = ({ heroData, setHeroData, onSave }) => {
  const handleChange = (field, value) => {
    if (field.includes('.')) {
      const [section, subsection, part] = field.split('.');
      setHeroData(prev => ({
        ...prev,
        [section]: {
          ...prev[section],
          [subsection]: {
            ...prev[section][subsection],
            [part]: value
          }
        }
      }));
    } else {
      setHeroData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h2 className="text-lg font-semibold mb-6">Edit Hero Section</h2>
      
      {/* Title */}
      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Main Title
        </label>
        <input
          type="text"
          value={heroData.title}
          onChange={(e) => handleChange('title', e.target.value)}
          className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
        />
      </div>

      {/* Main Text Lines */}
      <div className="space-y-6 mb-6">
        <div>
          <h3 className="font-medium mb-3">Line 1</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-600 mb-1">Blue Text</label>
              <input
                type="text"
                value={heroData.mainText.line1.part1}
                onChange={(e) => handleChange('mainText.line1.part1', e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">White Text</label>
              <input
                type="text"
                value={heroData.mainText.line1.part2}
                onChange={(e) => handleChange('mainText.line1.part2', e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="font-medium mb-3">Line 2</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-600 mb-1">Blue Text</label>
              <input
                type="text"
                value={heroData.mainText.line2.part1}
                onChange={(e) => handleChange('mainText.line2.part1', e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">White Text</label>
              <input
                type="text"
                value={heroData.mainText.line2.part2}
                onChange={(e) => handleChange('mainText.line2.part2', e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="font-medium mb-3">Line 3</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-600 mb-1">White Text</label>
              <input
                type="text"
                value={heroData.mainText.line3.part1}
                onChange={(e) => handleChange('mainText.line3.part1', e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">Blue Text</label>
              <input
                type="text"
                value={heroData.mainText.line3.part2}
                onChange={(e) => handleChange('mainText.line3.part2', e.target.value)}
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Button Settings */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Button Text
          </label>
          <input
            type="text"
            value={heroData.buttonText}
            onChange={(e) => handleChange('buttonText', e.target.value)}
            className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Button Link
          </label>
          <input
            type="text"
            value={heroData.buttonLink}
            onChange={(e) => handleChange('buttonLink', e.target.value)}
            className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      <div className="mt-6">
        <button
          onClick={onSave}
          className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
        >
          Save Hero Section
        </button>
      </div>
    </div>
  );
};

export default HeroEditor;