import React, { useState } from 'react';

const TeamSection = () => {
  const [activeImage, setActiveImage] = useState('ceo'); // 'ceo' or 'cmo'

  return (
    <div className="relative bg-black min-h-screen w-full">
      <div className="flex flex-col items-center gap-12 p-8">
        <h3 className="font-['Poppins'] text-[40px] font-bold tracking-[0.4px] text-center text-white uppercase">
          the team behind it all
        </h3>
        
        <div className="grid grid-cols-3 gap-4 w-full max-w-[1400px]">
          {/* First column - Tagline */}
          <div className="relative bg-black rounded-lg p-8">
            <h1 className="font-['Poppins'] text-[48px] leading-tight tracking-[-1px] text-white">
              <div>COMMITTED<span className="text-[#195BD7]">TO</span> PEOPLE,</div>
              <div>COMMITTED TO <span className="text-[#195BD7]">THE FUTUR</span></div>
            </h1>
          </div>

          {/* Second column - CEO */}
          <div 
            className={`relative rounded-lg overflow-hidden cursor-pointer transition-all duration-300 ${
              activeImage === 'ceo' ? 'bg-[#ECE4CD]' : 'bg-[#D9D9D9]'
            }`}
            onClick={() => setActiveImage('ceo')}
          >
            {activeImage === 'ceo' && (
              <div className="p-6">
                <p className="font-['Inter'] text-[#FE8E1C] uppercase font-bold">
                  chief executive officer
                </p>
                <h2 className="font-['Inter'] text-2xl font-bold tracking-[-0.5px] text-[#333333]">
                  karim Hermi
                </h2>
              </div>
            )}
            <img
              src="https://framerusercontent.com/images/bWxx0WKqcxxTPiuJNk4mjYiGwn0.png"
              alt=""
              className={`w-full h-full object-cover transition-all duration-300 ${
                activeImage !== 'ceo' ? 'grayscale' : ''
              }`}
            />
          </div>

          {/* Third column - CMO */}
          <div 
            className={`relative rounded-lg overflow-hidden cursor-pointer transition-all duration-300 ${
              activeImage === 'cmo' ? 'bg-[#ECE4CD]' : 'bg-[#D9D9D9]'
            }`}
            onClick={() => setActiveImage('cmo')}
          >
            {activeImage === 'cmo' && (
              <div className="p-6">
                <p className="font-['Inter'] text-[#FE8E1C] uppercase font-bold">
                  chief marketing officer
                </p>
                <h2 className="font-['Inter'] text-2xl font-bold tracking-[-0.5px] text-[#333333]">
                  Hsin Trabelsi
                </h2>
              </div>
            )}
            <img
              src="https://framerusercontent.com/images/HVAlwqFnFrcTPi1dOzcNfuGb3M.png"
              alt=""
              className={`w-full h-full object-cover transition-all duration-300 ${
                activeImage !== 'cmo' ? 'grayscale' : ''
              }`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;