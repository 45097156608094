import React from 'react';
import specificity from '../assets/partners/specificity.webp';
import giz from '../assets/partners/giz.webp';
import ticdce from '../assets/partners/ticdce.webp';
import ost from '../assets/partners/ost.webp';
import startup from '../assets/partners/startup.png';
import darajafour from '../assets/partners/idwey.webp';

const TrustedBySection = () => {
  return (
    <div className="bg-black py-16 w-full">
      <h2 className="text-4xl font-bold text-white text-center mb-16">
        WE ARE TRUSTED BY
      </h2>
      
      <div className="relative overflow-hidden">
        <div className="flex logos-slide">
          {/* First set of logos */}
          <div className="flex flex-nowrap items-center gap-24">
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={specificity} alt="Specificity" className="h-34 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={giz} alt="GIZ" className="h-32 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={ticdce} alt="TICDCE" className="h-34 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={ost} alt="OST" className="h-32 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={startup} alt="Startup" className="h-32 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={darajafour} alt="Daraja Four" className="h-32 object-contain" />
            </div>
          </div>
          {/* Duplicate set for seamless scrolling */}
          <div className="flex flex-nowrap items-center gap-24">
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={specificity} alt="Specificity" className="h-34 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={giz} alt="GIZ" className="h-32 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={ticdce} alt="TICDCE" className="h-34 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={ost} alt="OST" className="h-32 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={startup} alt="Startup" className="h-32 object-contain" />
            </div>
            <div className="flex items-center justify-center min-w-[300px]">
              <img src={darajafour} alt="Daraja Four" className="h-32 object-contain" />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes slide {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-50%);
          }
        }

        .logos-slide {
          animation: slide 10s linear infinite;
          display: flex;
        }
      `}</style>
    </div>
  );
};

export default TrustedBySection;