import React from 'react';
import image1 from '../assets/sdg/1.avif'; // Image with people on street
import image2 from '../assets/sdg/2.avif'; // Person in red with phone
import image3 from '../assets/sdg/3.avif'; // People looking at devices

const SDGImpactSection = () => {
  return (
    <div className="bg-black py-16">
      <h1 className="text-5xl font-black text-white text-center mb-16 font-poppins">
        Empowering Impact: Tynass and The SDGs
      </h1>

      <div className="max-w-7xl mx-auto px-4">
        {/* Grid Container */}
        <div className="grid grid-cols-3 gap-0 auto-rows-fr">
          {/* First Box - Green */}
          <div className="bg-emerald-600 p-8">
            <h2 className="text-2xl font-bold text-white mb-4">
              SDG: Quality Education
            </h2>
            <p className="text-white text-lg">
              We boost Local Economies: By providing an engaging and immersive experience, 
              our solution can help to drive tourism and promote local businesses. This can 
              result in job creation and economic growth for local communities
            </p>
          </div>

          {/* Center Image */}
          <div className="relative">
            <img 
              src={image1} 
              alt="Local tourism" 
              className="w-full h-full object-cover"
            />
          </div>

          {/* Third Box - Light Green */}
          <div className="bg-green-50 p-8">
            <h2 className="text-2xl font-bold text-emerald-800 mb-4">
              SDG: Quality Education
            </h2>
            <p className="text-emerald-800 text-lg">
              By working with local governments and cultural organizations, we can help 
              to promote sustainable tourism practices that support local economies. For 
              example, by directing travelers to locally-owned businesses
            </p>
          </div>

          {/* Bottom Row - Left Image */}
          <div className="relative">
            <img 
              src={image2} 
              alt="Digital experience" 
              className="w-full h-full object-cover"
            />
          </div>

          {/* Bottom Center - Blue Box */}
          <div className="bg-sky-100 p-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              SDG: Quality Education
            </h2>
            <p className="text-gray-800 text-lg">
              We promote and create a new kind of attraction for tangible and 
              intangible cultural heritage (Monuments, dialects, traditions ...)
            </p>
          </div>

          {/* Bottom Right - Image */}
          <div className="relative">
            <img 
              src={image3} 
              alt="Community engagement" 
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SDGImpactSection;