import React from 'react';

const MissionEditor = ({ missionData, setMissionData, onSave }) => {
  return (
    <div className="max-w-3xl mx-auto">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <h3 className="text-lg font-semibold mb-6">Edit Mission Section</h3>

        {/* Mission */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Mission Statement
          </label>
          <textarea
            value={missionData.mission}
            onChange={(e) => setMissionData({...missionData, mission: e.target.value})}
            rows={4}
            className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>

        {/* Vision */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Vision Statement
          </label>
          <textarea
            value={missionData.vision}
            onChange={(e) => setMissionData({...missionData, vision: e.target.value})}
            rows={4}
            className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>

        {/* Goal */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Goal Statement
          </label>
          <textarea
            value={missionData.goal}
            onChange={(e) => setMissionData({...missionData, goal: e.target.value})}
            rows={4}
            className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>

        {/* Collaboration */}
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Collaboration Text
          </label>
          <textarea
            value={missionData.collaboration}
            onChange={(e) => setMissionData({...missionData, collaboration: e.target.value})}
            rows={3}
            className="block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          />
        </div>

        {/* Save Button */}
        <button
          onClick={onSave}
          className="w-full bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Save Mission Section
        </button>
      </div>
    </div>
  );
};

export default MissionEditor;