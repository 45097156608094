import React from 'react';
import defaultHeroImage from '../assets/hero.avif';

const AboutSection = ({ aboutData = {} }) => {
  // Provide default values to prevent undefined errors
  const {
    title = "Redefine Innovation with Tynass",
    description = "At Tynass, we've taken technology and innovation to a whole new level. We are passionately dedicated to bringing the absolute best experiences and solutions possible to our users. Our flagship product, Tynass Trips, is just one example of our commitment to pushing boundaries and redefining what's possible. With a focus on creativity, cutting-edge technology, and a deep understanding of our users' desires, we strive to bring immersive adventures and solutions that captivate, educate, and inspire. We believe in harnessing the power of innovation to reshape the way people experience the world, and we're excited to continue leading the way into the future of augmented and mixed reality, and this commitment is at the heart of our mission, vision, and core values.",
    heroImage = defaultHeroImage
  } = aboutData;

  return (
    <div className="bg-black min-h-screen py-20">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-white text-5xl font-bold text-center mb-16">
          {title}
        </h2>

        <div className="flex justify-center mb-16">
          <img 
            src={heroImage}
            alt="Innovation Hero"
            className="w-[600px] h-[600px] object-contain"
          />
        </div>

        <div className="max-w-4xl mx-auto">
          <p className="text-gray-300 text-lg leading-relaxed text-center">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;