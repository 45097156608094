import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#111111] border-t border-[rgba(0,0,0,0.05)]">
      <div className="container mx-auto px-4 py-16">
        {/* Main Content */}
        <div className="flex justify-between max-w-6xl mx-auto">
          {/* Left Side - Navigation */}
          <div className="flex flex-col">
            <h3 className="text-white font-semibold mb-6 font-poppins">Navigation</h3>
            <nav className="flex flex-col space-y-4">
              <Link to="/" className="text-gray-400 hover:text-gray-300 font-poppins font-medium">
                Tynass Trips
              </Link>
              <Link to="/about" className="text-gray-400 hover:text-gray-300 font-poppins font-medium">
                About Us
              </Link>
              <Link to="/contact" className="text-gray-400 hover:text-gray-300 font-poppins font-medium">
                Contact Us
              </Link>
            </nav>
          </div>

          {/* Right Side - Building Drawing and Store Links */}
          <div className="flex flex-col items-end">
            <img 
              src="https://framerusercontent.com/images/iMGxkukhZhzUALPBrRnKfngdHU.svg"
              alt="Building Drawing" 
              className="w-48 mb-6"
            />
            <div className="flex gap-2">
              <a 
                href="https://play.google.com/store/apps/details?id=com.tynass.tynass&hl=en&gl=US"
                target="_blank"
                rel="noopener"
                className="h-10"
              >
                <img 
                  src="https://framerusercontent.com/images/mZqzn2R57q3cOMRrWfILXe33ws.png"
                  alt="Get it on Google Play"
                  className="h-full"
                />
              </a>
              <a 
                href="https://apps.apple.com/us/app/tynass-trips/id1639800556"
                target="_blank"
                rel="noopener"
                className="h-10"
              >
                <img 
                  src="https://framerusercontent.com/images/067iLGvdIpi7I6ypIJ8XN0khXQ.webp"
                  alt="Download on the App Store"
                  className="h-full"
                />
              </a>
            </div>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="max-w-6xl mx-auto">
          <div className="mt-16 pt-8 border-t border-white/20 flex justify-between items-center">
            <p className="text-gray-400 text-sm font-poppins font-bold">
              © 2023 TynassIT.
            </p>
            <div className="flex gap-6">
              <a href="https://www.linkedin.com/company/tynass-it" target="_blank" rel="noopener">
                <img 
                  src="data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cg%20transform%3D%22translate(2.778%202.778)%22%20id%3D%22ss1261205471_1%22%3E%3Cpath%20d%3D%22M%2014.444%2014.444%20L%2011.477%2014.444%20L%2011.477%209.389%20C%2011.477%208.003%2010.95%207.229%209.853%207.229%20C%208.66%207.229%208.036%208.035%208.036%209.389%20L%208.036%2014.444%20L%205.176%2014.444%20L%205.176%204.815%20L%208.036%204.815%20L%208.036%206.112%20C%208.036%206.112%208.896%204.521%2010.94%204.521%20C%2012.982%204.521%2014.444%205.768%2014.444%208.347%20Z%20M%201.764%203.554%20C%200.789%203.554%200%202.758%200%201.777%20C%20-0%200.796%200.789%20-0%201.764%20-0%20C%202.738%20-0%203.527%200.796%203.527%201.777%20C%203.527%202.758%202.738%203.554%201.764%203.554%20Z%20M%200.287%2014.444%20L%203.269%2014.444%20L%203.269%204.815%20L%200.287%204.815%20L%200.287%2014.444%20Z%22%20fill%3D%22rgb(255%2C255%2C255)%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E"
                  alt="LinkedIn" 
                  className="w-5 h-5" 
                />
              </a>
              <a href="https://www.facebook.com/TynassIt" target="_blank" rel="noopener">
                <img 
                  src="data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cpath%20d%3D%22M%204.217%208.522%20L%205.971%208.522%20L%205.971%2015.742%20C%205.971%2015.884%206.086%2016%206.229%2016%20L%209.203%2016%20C%209.345%2016%209.461%2015.884%209.461%2015.742%20L%209.461%208.556%20L%2011.477%208.556%20C%2011.608%208.556%2011.718%208.457%2011.733%208.327%20L%2012.039%205.669%20C%2012.048%205.596%2012.025%205.523%2011.976%205.468%20C%2011.927%205.413%2011.857%205.381%2011.783%205.381%20L%209.461%205.381%20L%209.461%203.715%20C%209.461%203.213%209.731%202.958%2010.265%202.958%20L%2011.783%202.958%20C%2011.926%202.958%2012.041%202.842%2012.041%202.7%20L%2012.041%200.26%20C%2012.041%200.117%2011.926%200.002%2011.783%200.002%20L%209.69%200.002%20C%209.659%200.001%209.627%20-0%209.595%200%20C%209.232%200%207.969%200.071%206.972%200.988%20C%205.868%202.005%206.021%203.222%206.058%203.433%20L%206.058%205.381%20L%204.217%205.381%20C%204.074%205.381%203.959%205.497%203.959%205.639%20L%203.959%208.263%20C%203.959%208.332%203.986%208.398%204.034%208.446%20C%204.083%208.494%204.148%208.522%204.217%208.522%20Z%22%20fill%3D%22hsl(0%2C%200%25%2C%20100%25)%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E"
                  alt="Facebook" 
                  className="w-4 h-4" 
                />
              </a>
              <a href="https://www.instagram.com/tynassit/" target="_blank" rel="noopener">
                <img 
                  src="data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpath%20d%3D%22M%200%200%20L%2020%200%20L%2020%2020%20L%200%2020%20Z%22%20fill%3D%22transparent%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%207.5%2010%20C%207.5%208.619%208.619%207.5%2010%207.5%20C%2011.381%207.5%2012.5%208.619%2012.5%2010%20C%2012.5%2011.381%2011.381%2012.5%2010%2012.5%20C%208.619%2012.5%207.5%2011.381%207.5%2010%20Z%22%20fill%3D%22rgb(255%2C255%2C255)%22%3E%3C%2Fpath%3E%3Cpath%20d%3D%22M%2013.438%202.188%20L%206.563%202.188%20C%204.146%202.188%202.188%204.146%202.188%206.563%20L%202.188%2013.438%20C%202.188%2015.854%204.146%2017.813%206.563%2017.813%20L%2013.438%2017.813%20C%2015.854%2017.813%2017.813%2015.854%2017.813%2013.438%20L%2017.813%206.563%20C%2017.813%204.146%2015.854%202.188%2013.438%202.188%20Z%20M%2010%2013.75%20C%207.929%2013.75%206.25%2012.071%206.25%2010%20C%206.25%207.929%207.929%206.25%2010%206.25%20C%2012.071%206.25%2013.75%207.929%2013.75%2010%20C%2013.75%2012.071%2012.071%2013.75%2010%2013.75%20Z%20M%2014.063%206.875%20C%2013.545%206.875%2013.125%206.455%2013.125%205.938%20C%2013.125%205.42%2013.545%205%2014.063%205%20C%2014.58%205%2015%205.42%2015%205.938%20C%2015%206.455%2014.58%206.875%2014.063%206.875%20Z%22%20fill%3D%22rgb(255%2C255%2C255)%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E"
                  alt="Instagram" 
                  className="w-5 h-5" 
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;