import React, { useRef, useState } from 'react';

const TrustedByEditor = ({ trustedByData, setTrustedByData, onSave }) => {
  const fileInputRefs = useRef([]);
  const [uploadError, setUploadError] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);

  if (!trustedByData?.partners) {
    return <div>Loading...</div>;
  }

  const handleTitleChange = (newTitle) => {
    setTrustedByData({
      ...trustedByData,
      title: newTitle
    });
  };

  const handlePartnerChange = (index, field, value) => {
    const newPartners = [...trustedByData.partners];
    newPartners[index] = {
      ...newPartners[index],
      [field]: value
    };
    setTrustedByData({
      ...trustedByData,
      partners: newPartners
    });
  };

  const handleLogoUpload = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setUploadError('Image size should be less than 2MB');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        handlePartnerChange(index, 'logo', e.target.result);
        setUploadError(null);
      };
      reader.onerror = () => {
        setUploadError('Error reading file');
      };
      reader.readAsDataURL(file);
    }
  };

  const addPartner = () => {
    const newPartner = {
      id: Date.now(),
      name: '',
      logo: '',
      url: ''
    };
    
    setTrustedByData({
      ...trustedByData,
      partners: [...trustedByData.partners, newPartner]
    });
  };

  const removePartner = (index) => {
    const confirmed = window.confirm('Are you sure you want to remove this partner?');
    if (confirmed) {
      const newPartners = [...trustedByData.partners];
      newPartners.splice(index, 1);
      setTrustedByData({
        ...trustedByData,
        partners: newPartners
      });
    }
  };

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    if (draggedIndex === null || draggedIndex === index) return;

    const newPartners = [...trustedByData.partners];
    const draggedPartner = newPartners[draggedIndex];
    newPartners.splice(draggedIndex, 1);
    newPartners.splice(index, 0, draggedPartner);
    
    setTrustedByData({
      ...trustedByData,
      partners: newPartners
    });
    setDraggedIndex(index);
  };

  const handleDragEnd = () => {
    setDraggedIndex(null);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm">
      <h3 className="text-lg font-semibold mb-4">Edit Trusted Partners Section</h3>
      
      {/* Section Title */}
      <div className="mb-6">
        <label className="block text-sm font-medium mb-2">Section Title</label>
        <input
          type="text"
          value={trustedByData.title}
          onChange={(e) => handleTitleChange(e.target.value)}
          className="w-full rounded-md border px-4 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
          placeholder="Enter section title"
        />
      </div>

      {uploadError && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded">
          {uploadError}
        </div>
      )}

      {/* Partners List */}
      <div className="space-y-6">
        {trustedByData.partners.map((partner, index) => (
          <div
            key={partner.id}
            className="p-6 bg-gray-100 rounded-lg cursor-move"
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
          >
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center gap-2">
                <span className="text-gray-400">☰</span>
                <h4 className="font-medium text-lg">Partner {index + 1}</h4>
              </div>
              <button
                onClick={() => removePartner(index)}
                className="px-3 py-1.5 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
              >
                Remove
              </button>
            </div>

            <div className="space-y-6">
              {/* Partner Name */}
              <div>
                <label className="block text-sm font-medium mb-2">Partner Name</label>
                <input
                  type="text"
                  value={partner.name}
                  onChange={(e) => handlePartnerChange(index, 'name', e.target.value)}
                  className="w-full rounded-md border px-4 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  placeholder="Enter partner name"
                />
              </div>

              {/* Logo Upload */}
              <div>
                <label className="block text-sm font-medium mb-2">Logo</label>
                <div className="flex flex-col items-start gap-4">
                  {partner.logo && (
                    <div className="w-full flex justify-center bg-white p-4 rounded-lg border">
                      <img 
                        src={partner.logo} 
                        alt={partner.name} 
                        className="h-32 max-w-full object-contain"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    ref={el => fileInputRefs.current[index] = el}
                    onChange={(e) => handleLogoUpload(index, e)}
                    accept="image/*"
                    className="w-full text-sm text-gray-500
                      file:mr-4 file:py-2 file:px-4
                      file:rounded-md file:border-0
                      file:text-sm file:font-medium
                      file:bg-blue-50 file:text-blue-700
                      hover:file:bg-blue-100"
                  />
                  <p className="text-sm text-gray-500">
                    Recommended size: 200x100 pixels, max 2MB
                  </p>
                </div>
              </div>

              {/* Partner URL */}
              <div>
                <label className="block text-sm font-medium mb-2">Website URL</label>
                <input
                  type="url"
                  value={partner.url}
                  onChange={(e) => handlePartnerChange(index, 'url', e.target.value)}
                  className="w-full rounded-md border px-4 py-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                  placeholder="https://example.com"
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Add Partner Button */}
      <button
        onClick={addPartner}
        className="mt-8 w-full px-4 py-3 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors font-medium"
      >
        Add New Partner
      </button>

      {/* Save Button */}
      <button
        onClick={onSave}
        className="mt-4 w-full px-4 py-3 bg-green-500 text-white rounded hover:bg-green-600 transition-colors font-medium"
      >
        Save Changes
      </button>
    </div>
  );
};

export default TrustedByEditor;